
@font-face {
  font-family: "product-sans-regular";
  src:url('../../assets/fonts/product-sans/ProductSansRegular.ttf') format("truetype");
}
.ant-drawer-content{
  .ant-drawer-header {
    position: relative;
    padding: 12px 24px !important;
    .ant-drawer-close {
      padding: 12px 20px !important;
    }
}
  .ant-drawer-body{
    padding: 4px 24px !important;
  }
}

.search-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 1199px) {
    display: block;
   }
   >div{
     margin: 16px 0px;
   }
  > div:nth-child(2){
      > .custom-search{
        width:350px;
        @media (max-width: 1000px) { 
          width:100%;
         }
        .ant-input-search-icon::before {
         border:0;
      }
      .ant-input-search-icon::before {
        border: 0;
      }
    }
  }
  > div:nth-child(3) {
    @media (max-width: 1000px) {
      text-align: right;
    }
  }
}
.box {
  margin-bottom: 1.875rem;
  background-color: #fff;
  box-shadow: 0 1px 2px rgba(216, 223, 227, 0.5),
    0 1px 2px rgba(216, 223, 227, 0.5);
  border-radius: 0.25rem;
  overflow: hidden;
  &__header {
    display: flex;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -ms-flex-align: center;
    align-items: center;
    padding: 0.625rem 1.25rem;
    transition: opacity 0.25s ease-out;
    .box-title {
      flex: 1 1 auto;
      min-height: 1.75rem;
      margin: 0;
      font-size: 0.875rem;
      font-weight: 600;
      line-height: 1.75rem;
      text-transform: uppercase;
      letter-spacing: 0.0625rem;
    }
    .box-options {
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      padding-left: 1.25rem;
    }
  }
  &__content {
    transition: opacity 0.25s ease-out;
    width: 100%;
    margin: 0 auto;
    padding: 1.25rem 1.25rem 1px;
    overflow-x: visible;
    &.p-0 {
      padding: 0;
    }
  }
  &__footer {
    padding: 0.625rem 1.25rem;
  }
}
.btn-refresh {
  background: #f5f5f5 0% 0% no-repeat padding-box !important;
  border: 0 !important;
  color: #0d75ff !important;
  opacity: 1;
}
.grid-table {
  .ant-table-thead > tr > th {
    font-weight: 600;
    text-align: left;
    border-bottom: 0;
    letter-spacing: 0px;
    padding: 4px 16px;
    font-size: 12px;
  }
  .ant-table-tbody > tr > td {
    padding: 8px 16px;
    font-size: 12px;
  }
}
.edit-box {
  display: flex;
  justify-content: flex-end;
  .anticon-delete {
    svg {
      fill: rgb(245, 34, 45);
    }
  }
}
.ant-tag {
  cursor: pointer !important;
  padding: 4px 7px !important;
  border: 0 !important;
  font-weight: 600;
}
.ant-form-item {
  margin-bottom: 16px !important;
}
.warranty-form {
  margin-bottom: 8px !important;
  &__grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1rem;
    .ant-form-item {
      margin-bottom: 16px !important;
    }
  }
}

.ant-drawer {
  z-index: 9999 !important;
}
.ant-select-dropdown {
  z-index: 100000 !important;
}
.info-card {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding: 20px;
  @media (max-width: 767px) {
    grid-template-columns: 1fr;
   }
  grid-gap: 1rem;
  > div {
    background: #fff;
    box-shadow: 0 1px 2px rgba(216, 223, 227, 0.5),
      0 1px 2px rgba(216, 223, 227, 0.5);
    border-radius: 12px 0px 12px 0px;
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    a{
    > div {
      flex: 1 1 auto;
      min-height: 1px;
      padding:0.75rem 1.25rem;
      > div {
        display: flex;
        flex-wrap: wrap;
        align-items: center !important;
        &:nth-child(1) {
          margin-bottom: 1rem;
        }
        &:nth-child(2) {
          padding-top: 1rem;
          border-top: 1px dashed #eee;
        }
        > .col-auto {
          flex: 0 0 auto;
          width: auto;
          max-width: 100%;
          margin-right: 1rem;
        }
        > .col-full {
          flex-basis: 0;
          flex-grow: 1;
          min-width: 0;
          max-width: 100%;
          margin-right: 1rem;
        }
      }
    }
    }
    &:nth-child(1) {
      .anticon {
        color: #0d75ff;
        font-size: 24px;
      }
      h1{
        color: #0d75ff;
      }
    }
    &:nth-child(2) {
      .anticon {
        color:#ffbd0d;
        font-size: 24px;
      }
      h1{
        color: #ffbd0d;
      }
    }
    &:nth-child(3) {
      .anticon {
        color: #29c768;
        font-size: 24px;
      }
      h1{
        color: #29c768;
      }
    }
  }
}
/*Form css*/
.ant-btn {
  font-weight: 600 !important;
  height: 36px !important;
  text-transform: capitalize;
}
.ant-btn  + .ant-btn{
  margin-left: 12px;
}
.ant-input-number-input,
.ant-input {
  height: 36px !important;
}
.ant-select-multiple .ant-select-selector {
  background-color: transparent !important;
}
.ant-input-affix-wrapper {
  padding: 0 11px !important;
}
.ant-form-item-label {
  text-align: left !important;
}
.ant-table-pagination.ant-pagination {
  margin: 16px !important;
}
.ant-notification {
  z-index: 99999 !important;
}
.ant-select-selector {
  height: 36px !important;
  padding: 0 12px !important;
}
/*error pages*/
.error-container {
  display: grid;
  place-items: center;
  grid-template-columns: 1fr;
  width: 100vw;
  min-height: 100vh;
  text-align: center;
  background-color: #eee;
  > div {
    background: #fff;
    padding: 3rem 0;
    width: 100%;
    h1 {
      font-size: 64px;
      font-weight: 600;
      color: #d9363e;
    }
    form {
      max-width: 400px;
      margin: 0 auto;
      margin-bottom: 20px;
    }
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 600 !important;
  margin-bottom: 0 !important;
}
/*font weight*/
.fw-600 {
  font-weight: 600;
}
@texts: {
  capital: capitalize;
  upper: uppercase;
  lower: lowercase;
};
each(@texts, {
    .text-@{key} {
      text-transform: @value;
    }
  });
.custom-radio {
  .ant-radio-button-wrapper {
    border: 0 !important;
    background: #eee;
    line-height: 36px;
    padding: 0 20px;
    border-radius: 4px;
    height: 36px;
    text-align: center;
    min-width: 120px;
    text-transform: capitalize;
    font-weight: 600;
    position: relative;
    &:not(:first-child)::before {
      width: 0;
    }
    .radio-icon {
      position: absolute;
      top: 4px;
      right: 4px;
      visibility: hidden;
      transition: 0.25s linear;
    }
    &.ant-radio-button-wrapper-checked {
      .radio-icon {
        visibility: visible;
      }
    }
  }
}
.loader {
  position: fixed;
  width: 100vw;
  height: 100vh;
  display: grid;
  place-items: center;
}

.tabs-fixed{
  .ant-tabs-nav{
    background: #fff;
    position: fixed;
    top: 64px;
    left: 0;
    height: calc(100vh - 64px);
    width: 230px;
    z-index: 9;
    padding-top: 16px;
}
.ant-tabs-content-holder{
  margin-left: 200px;
}
}
.custom-modal{
  .ant-modal-close{
    display: none;
  }
}
.flex-bwn {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.flex-bwn-end {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
.flex{
  display: flex;
}
.my-3{
  margin: 1rem 0 .5rem;
}
.my-1{
 margin:1rem 0;
}
dd{
  > span{
    font-weight: 600;
  }
}
.serial-bg{
 padding: 12px 16px;
 background: #E6F1FF 0% 0% no-repeat padding-box;
 border-radius: 2px;
opacity: 1;
  .btn-rnd{
  text-align: center;
  vertical-align: middle;
  background: #D0E5FF 0% 0% no-repeat padding-box;
  border-radius: 15px;
  opacity: 1;
  padding: 0.275rem 0.45rem;
  font-weight: 600;
  line-height: 1.5;
  border-radius: 25px;
  box-shadow: 0 1px 2px rgba(216, 223, 227, 0.5), 0 1px 2px rgba(216, 223, 227, 0.5);
  transition: 0.25s linear;
  margin-right: 5px;
  cursor: pointer;
  .anticon {
     color: #0d75ff;
  }
 }
}
.br-tag{
  border-radius: 20px !important;
}

.chart-container{
  background-color: white;
  height: 300px;
  margin-bottom: 1.875rem;
}
.ant-btn[disabled]{
 color:#fff !important;
}
.ant-modal-wrap {
  z-index: 99999;
}
.ant-popover-buttons{
button{
min-width: auto;
padding: 5px;
height: 25px !important;
line-height: 10px;
font-size: 12px;
font-weight: 400;
}

}

.grid-splits{
  display: grid;
  grid-template-columns: 450px 1fr;
  grid-gap: 15px;
  > div:first-child{
    background-color: #fff;
  }
}

.error-container {
  display: grid;
  place-items: center;
  grid-template-columns: 1fr;
  width: 100vw;
  min-height: 100vh;
  text-align: center;
  background-color: #eee;
  > div {
    background: #fff;
    padding: 3rem 0;
    width: 100%;
    h1 {
      font-size: 64px;
      font-weight: 600;
      color: #d9363e;
  }
}
}
.ant-popover-content{
  box-shadow: rgb(217 217 217) 1px 1px 6px;
}
.ant-btn{
  transition: color 0.25s;
  &:hover,&:active,&:visited{
    color: #000 !important;
  }
}
.pt-0{
padding-top: 0;
}
.mx-0{
 margin-left: 0 !important;
 margin-right: 0 !important;
}
.tti-popover{
  .ant-upload{
  display: block !important;
  .ant-btn{
    width: 100%;
  }
  }
}
.m-t-1{
  margin-top: 1rem;
}
.m-l-0{
margin-left: 0;
}
@primary-color: #005cb2;@link-color: #005cb2;@success-color: #52c41a;@warning-color: #faad14;@error-color: #FF3333;@border-color-base: #d9d9d9;@box-shadow-base: none !important;@font-family: "product-sans-regular", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
  "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
  "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";