.loading {
    display: flex;
    justify-content: center;
    margin: 2rem;
    &::after{
      content: "";
      width: 40px;
      height: 40px;
      border: 4px solid #dddddd;
      border-top-color: #005cb2;
      border-radius: 50%;
      animation: spin 0.8s 0.1s ease-in-out infinite both;
    }
  }
  @keyframes spin {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  .ripple {
    position: relative;
    display: block;
    margin: auto;
    width: 64px;
    height: 64px;
  }
  .ripple div {
    position: absolute;
    border: 4px solid #005cb2;
    opacity: 1;
    border-radius: 50%;
    animation: ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
  }
  .ripple div:nth-child(2) {
    animation-delay: -0.5s;
  }
  @keyframes ripple {
    0% {
      top: 28px;
      left: 28px;
      width: 0;
      height: 0;
      opacity: 1;
    }
    100% {
      top: -1px;
      left: -1px;
      width: 58px;
      height: 58px;
      opacity: 0;
    }
  }
  
@primary-color: #005cb2;@link-color: #005cb2;@success-color: #52c41a;@warning-color: #faad14;@error-color: #FF3333;@border-color-base: #d9d9d9;@box-shadow-base: none !important;@font-family: "product-sans-regular", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
  "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
  "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";